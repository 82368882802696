import React, { ReactElement } from 'react'
import { graphql, PageProps } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from '../utilities/linkResolver'
import { Page } from '../components/page'

const PageTemplate = (props: PageProps<GatsbyTypes.PageByUidQuery, Record<string, never>, unknown>): ReactElement => {
  return <Page {...props} />
}

const PageTemplateWithPreview = withPrismicPreview(PageTemplate/*, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY ?? ``,
  linkResolver,
}]*/)

export default PageTemplateWithPreview
export { PageTemplateWithPreview as PageTemplate }

export const PageQuery = graphql`
  query PageByUid($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicPageDataBodyCarousel {
            id
            items {
              caption {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(height: 500)
                dimensions {
                  height
                  width
                }
              }
            }
            slice_type
          }
          ... on PrismicPageDataBodySplit {
            id
            primary {
              alignment
              cta_link {
                document {
                  ...TargetDocumentFragment
                }
                ...LinkFragment
              }
              cta_text
              image {
                alt
                gatsbyImageData(height: 500)
                dimensions {
                  height
                  width
                }
              }
              text {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicPageDataBodyText {
            id
            primary {
              cta_link {
                document {
                  ...TargetDocumentFragment
                }
                ...LinkFragment
              }
              cta_text
              text {
                richText
                text
              }
            }
            slice_type
          }
        }
        description
        image {
          alt
          gatsbyImageData
          dimensions {
            height
            width
          }
        }
        parent {
          type
          uid
        }
        title
      }
      type
      uid
      _previewable
    }
  }
`